import { CreateQuestParams, QuestCategory, QuestTask } from '@app/types';

const keysForParsing = [
  'projectName',
  'contactPerson',
  'campaignName',
  'description',
  'icon',
  'image',
  'taskTitle1',
  'taskDescription1',
  'taskUrl1',
  'taskTitle2',
  'taskDescription2',
  'taskUrl2',
  'taskTitle3',
  'taskDescription3',
  'taskUrl3',
  'taskTitle4',
  'taskDescription4',
  'taskUrl4',
  'taskTitle5',
  'taskDescription5',
  'taskUrl5',
  'taskTitle6',
  'taskDescription6',
  'taskUrl6',
  'taskTitle7',
  'taskDescription7',
  'taskUrl7',
  'taskTitle8',
  'taskDescription8',
  'taskUrl8',
];

type ParsedTask = {
  taskTitle: string;
  taskDescription: string;
  taskUrl: string;
};

type ParsedQuestData = {
  projectName: string;
  contactPerson: string;
  campaignName: string;
  description: string;
  icon: string;
  image: string;
  tasks: ParsedTask[];
};

function transformGoogleDriveUrl(originalUrl: string) {
  const fileIdPattern1 = /\/file\/d\/(.*?)\//;
  const fileIdPattern2 = /open\?id=(.*)/;

  let match = originalUrl.match(fileIdPattern1);

  if (match && match[1]) {
    const fileId = match[1];

    return `https://drive.google.com/uc?export=view&id=${fileId}`;
  }

  match = originalUrl.match(fileIdPattern2);

  if (match && match[1]) {
    const fileId = match[1];

    return `https://drive.google.com/uc?export=view&id=${fileId}`;
  }

  throw new Error('Invalid Google Drive URL');
}

const mapParsedQuestDataToQuest = (
  parsedQuest: ParsedQuestData,
): Partial<CreateQuestParams> => {
  return {
    _id: `_${parsedQuest.projectName.replace(/\s/, '')}`,
    category: QuestCategory.Partners,
    title: parsedQuest.campaignName,
    description: parsedQuest.description,
    icon: parsedQuest.icon ? transformGoogleDriveUrl(parsedQuest.icon) : '',
    image: parsedQuest.image ? transformGoogleDriveUrl(parsedQuest.image) : '',
    tasks: parsedQuest.tasks.map((task, index) => ({
      id: index,
      title: task.taskTitle,
      description: task.taskDescription,
      url: task.taskUrl,
    })) as QuestTask[],
  };
};

export const parseQuestData = (
  questStr: string,
): Partial<CreateQuestParams | null> => {
  try {
    const parsedQuest = questStr.split('\t');
    const isEmpty = parsedQuest.every((value) => !value.trim());

    if (isEmpty) {
      return null;
    }

    let parsedQuestData = parsedQuest.reduce((acc, value, index) => {
      const key = keysForParsing[index];

      if (key.includes('task')) {
        const taskIndex = parseInt(key[key.length - 1]);

        if (!acc.tasks) {
          acc.tasks = [];
        }

        if (!acc.tasks[taskIndex - 1]) {
          acc.tasks[taskIndex - 1] = {} as ParsedTask;
        }

        acc.tasks[taskIndex - 1][key.replace(/\d+$/, '') as keyof ParsedTask] =
          value.trim();
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      acc[keysForParsing[index] as keyof ParsedQuestData] = value.trim() as any;

      return acc;
    }, {} as ParsedQuestData);

    parsedQuestData = {
      ...parsedQuestData,
      tasks: parsedQuestData.tasks.filter(
        (task) => task.taskTitle !== 'no' || !!task.taskTitle,
      ),
    };

    return mapParsedQuestDataToQuest(parsedQuestData);
  } catch (e) {
    console.error(e);

    return null;
  }
};
