import React from 'react';
import clsx from 'clsx';
import { useFormContext, RegisterOptions } from 'react-hook-form';

import s from './FormSelect.module.scss';

type Option = {
  value: string;
  label: string;
};
interface FormSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  required?: boolean;
  label: string;
  name: string;
  options: Option[];
  validation?: RegisterOptions;
  selectColor?: 'brown' | 'dark-brown';
  defaultValue?: Option['value'];
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  name,
  options,
  className,
  selectColor = 'dark-brown',
  required = false,
  validation = {},
  defaultValue,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = Boolean(errors?.[name]);

  return (
    <div className={clsx(s.root, className)}>
      <label className={s.label} htmlFor={name}>
        {label}
        {!required && <span className={s.optional}>(optional)</span>}
      </label>
      <select
        {...rest}
        className={clsx(s.select, s[selectColor], {
          [s.errorSelect]: hasError,
        })}
        {...register(name, { required: required })}
        id={name}
        defaultValue={defaultValue}
      >
        {options.map((option) => (
          <option key={option.value} className={s.option} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {hasError && (
        <span className={s.error}>
          {errors[name]?.type === 'required' && 'This field is required'}
        </span>
      )}
    </div>
  );
};

export default FormSelect;
