import { CreateQuestParams, Draft, DraftRaw, QuestRaw } from '@app/types';
import { CacheService } from '../CacheService';
import { mapCreateQuestParamsToQuestRaw } from '@app/pages/Quests/mapper';

export class DraftService {
  constructor(private readonly cacheService: CacheService) {}

  createOrUpdateDraft(data: Draft) {
    const draftId = data.draftId || `${Date.now()}`;
    const drafts = this.cacheService.get<Draft[]>('drafts') || [];

    const draftIndex = drafts.findIndex((item) => item.draftId === draftId);

    if (draftIndex !== -1) {
      const updatedDrafts = drafts.map((item, index) => {
        if (index === draftIndex) {
          return { ...data, draftId: draftId };
        }

        return item;
      });

      this.cacheService.set('drafts', updatedDrafts);
    } else {
      this.cacheService.set('drafts', [
        ...drafts,
        { ...data, draftId: draftId },
      ]);
    }
  }

  deleteDraft(draftId: string) {
    const drafts = this.cacheService.get<Draft[]>('drafts') || [];

    this.cacheService.set(
      'drafts',
      drafts.filter((item) => item.draftId !== draftId),
    );
  }

  getDraftById(draftId: string) {
    const drafts = this.cacheService.get<Draft[]>('drafts') || [];

    return drafts.find((item) => item.draftId === draftId);
  }

  getDraftedQuests(): DraftRaw[] {
    const drafts = this.cacheService.get<Draft[]>('drafts');

    if (!drafts) {
      return [];
    }

    return drafts.map((item) => mapCreateQuestParamsToQuestRaw(item));
  }
}
