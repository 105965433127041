import { ConfigService } from './ConfigService';
import { QuestService } from './QuestService';
import { CacheService } from './CacheService';
import { ApiService } from './ApiService';
import { AuthService } from './AuthService';
import { UserService } from './UserService';
import { DraftService } from './DraftService';
import { WithdrawalService } from './WithdrawalService';

export const configService = new ConfigService();

export const cacheService = new CacheService();
export const authService = new AuthService(cacheService);
export const apiService = new ApiService(
  configService,
  authService,
  cacheService,
);

export const questService = new QuestService(apiService, cacheService);
export const userService = new UserService(apiService, authService);

export const draftService = new DraftService(cacheService);
export const withdrawalService = new WithdrawalService();
