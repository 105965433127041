import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './IconButton.module.scss';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icon?: React.ReactElement;
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'medium' | 'large';
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  variant = 'primary',
  className,
  size = 'medium',
  ...restProps
}) => {
  const content = (
    <>{icon && React.cloneElement(icon, { className: styles.icon })}</>
  );

  const classNames = clsx(styles.root, className, styles[variant]);

  return (
    <button
      className={clsx(classNames, styles[size], {
        [styles.disabled]: restProps.disabled,
      })}
      {...restProps}
    >
      {content}
    </button>
  );
};
