import { CreateQuestParams, Draft, DraftRaw, QuestRaw } from '@app/types';

export const mapCreateQuestParamsToQuestRaw = (quest: Draft): DraftRaw => {
  return {
    draftId: quest.draftId,
    id: quest._id,
    icon: quest.icon || '',
    image: quest.image,
    category: quest.category,
    type: quest.type,
    title: quest.title,
    subtitle: quest.subtitle,
    description: quest.description,
    rewardAmount: quest.rewardAmount,
    url: quest.url,
    buttonTitle: quest.buttonTitle,
    lockMessage: quest.lockMessage,
    maxCompleteTimes: quest.maxCompleteTimes,
    regions: quest.regions,
    excludedRegions: quest.excludedRegions,
    whiteListedUsers: quest.whiteListedUsers,
    checker: quest.checker,
    dependsOn: quest.dependsOn?.map(Number),
    group: quest.group,
    tasks: quest.tasks,
  };
};
