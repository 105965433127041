import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ToastNotifications } from './ui-kit/ToastNotifications';
import { notifyError } from '@ui-kit/ToastNotifications';

import './styles/index.scss';

const AppWrapper = () => {
  window.addEventListener('error', (event) => {
    notifyError(
      `Something went wrong!\n${event.message}\nPlease, try to reload the page.`,
    );
  });

  window.addEventListener('unhandledrejection', (event) => {
    notifyError(
      `Something went wrong!\n${event.reason}\nPlease, try to reload the page.`,
    );
  });

  return (
    <>
      <App />
      <ToastNotifications />
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <AppWrapper />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
