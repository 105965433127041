import React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import s from './CheckerSelect.module.scss';
import { FormSelect, FormInput } from '@components';
import {
  optionsCheckerType,
  optionsLeague,
} from '@app/constants/selectOptions';
import { CheckerType } from '@app/types';

interface CheckerSelectProps {
  className?: string;
  label: string;
  selectColor?: 'brown' | 'dark-brown';
  baseName?: string;
  required?: boolean;
}

const getFieldName = (name: string, baseName?: string) => {
  return baseName ? `${baseName}.${name}` : name;
};

const getAdditionalFieldsByType = (
  checkerType: CheckerType,
  baseName?: string,
  selectColor?: 'brown' | 'dark-brown',
) => {
  switch (checkerType) {
    case CheckerType.TELEGRAM:
      return (
        <FormInput
          label="Telegram channel"
          name={getFieldName('telegramChannelId', baseName)}
          required
          inputColor={selectColor}
        />
      );
    case CheckerType.BALANCE:
      return (
        <FormInput
          type="number"
          step={0.01}
          label="Balance"
          name={getFieldName('balance', baseName)}
          required
          inputColor={selectColor}
        />
      );
    case CheckerType.LEAGUE:
      return (
        <FormSelect
          label="League"
          name={getFieldName('league', baseName)}
          options={optionsLeague}
          required
          selectColor={selectColor}
        />
      );
    case CheckerType.DEFAULT:
    case CheckerType.WALLET_ADDRESS:
    case CheckerType.EMAIL_SUBSCRIPTION:
    case CheckerType.SQUAD:
    case CheckerType.INTIVE_FRIEND:
      return null;
    default:
      return null;
  }
};

export const CheckerSelect: React.FC<CheckerSelectProps> = ({
  className,
  label,
  baseName,
  required,
  selectColor = 'dark-brown',
}) => {
  const { watch } = useFormContext();
  const checker: CheckerType = watch(getFieldName('checker', baseName));

  return (
    <div className={clsx(s.root, className)}>
      <FormSelect
        className={s.input}
        label={label}
        name={getFieldName('checker', baseName)}
        options={optionsCheckerType}
        required={required}
        selectColor={selectColor}
      />
      {getAdditionalFieldsByType(checker, baseName, selectColor)}
    </div>
  );
};
