/* eslint-disable jsx-a11y/alt-text */
import clsx from 'clsx';

import { BadgePoints } from '../BadgePoints';
import closeIcon from '@icons/close.svg';

import styles from './QuestItem.module.scss';

interface QuestItemProps {
  level?: number;
  className?: string;
  icon: string;
  title: string;
  desc?: string;
  type?: string;
  scoreAmount?: number | string;
  isLocked?: boolean;
  lockedMessage?: string;
  isCompleted?: boolean;
  completeTimes?: number;
  maxCompleteTimes?: number;
  itemQuantity?: number;
  itemCompleted?: number;
  onClick?: () => void;
}

export const QuestItem: React.FC<QuestItemProps> = ({
  className,
  icon,
  title,
  desc,
  lockedMessage,
  scoreAmount,
  completeTimes,
  maxCompleteTimes,

  onClick,
}) => (
  <div className={clsx(styles.root, className)} onClick={onClick}>
    <div className={styles.content}>
      <div className={styles.wrap}>
        <BadgePoints
          variant="default"
          size="small"
          value={scoreAmount ? scoreAmount : 'Reward'}
          className={styles.badge}
        />

        {maxCompleteTimes === completeTimes ? (
          <BadgePoints
            variant="finished"
            size="small"
            value="Completed"
            className={styles.badge}
          />
        ) : null}
      </div>

      <h4 className={styles.title}>{!title ? 'Title must be here' : title}</h4>
      <p className={styles.desc}>{!desc ? 'Subtitle must be here' : desc}</p>
      <p className={styles.desc}>{lockedMessage}</p>
      {maxCompleteTimes && (
        <p className={styles.limited}>
          <span className={styles.limitedDesc}>Limited:</span>
          <span className={styles.available}>
            <span>
              {completeTimes ? completeTimes?.toLocaleString('en-US') : 0}
            </span>{' '}
            <span>/</span>
            <span>{maxCompleteTimes.toLocaleString('en-US')}</span>
          </span>
        </p>
      )}
    </div>
    <div className={styles.iconWrap}>
      {icon ? (
        <img src={icon} className={styles.icon} alt="Quest Icon" />
      ) : (
        <div className={styles.iconState}>img</div>
      )}
    </div>
  </div>
);
