import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './Sidebar.module.scss';
import { NAV_LINKS } from '@app/constants/NavLinks';

interface SidebarProps {
  className?: string;
}

export const Sidebar: React.FC<SidebarProps> = ({ className }) => {
  return (
    <aside className={clsx(styles.root, className)}>
      <h1 className={styles.title}>Admin panel</h1>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          {NAV_LINKS.map((el) => (
            <li key={el.label} className={styles.navItem}>
              <NavLink to={el.href} className={styles.navLink}>
                {el.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};
