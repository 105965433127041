import cn from 'classnames';

import { WithdrawalsToDoCard } from '../WithdrawalsToDoCard';
import { WithdrawalToDo, WithdrawalType } from '@app/types/withdrawal';

import s from './WithdrawalsToDo.module.scss';

interface WithdrawalsToDoProps {
  type: WithdrawalType;
  items: WithdrawalToDo[] | null;
  className?: string;
}

export const WithdrawalsToDo: React.FC<WithdrawalsToDoProps> = ({
  type,
  items,
  className,
}) => {
  const filtered = items?.filter((el) => !el.user.isSuspicious);
  const total = filtered?.length ?? 0;

  return (
    <div className={cn(s.root, className)}>
      <h3 className={s.total}>
        Total: {total} ({type === WithdrawalType.Referral && 'Referrer'}
        {type === WithdrawalType.TournamentWin && 'Winners'})
      </h3>
      <div className={s.winners}>
        {filtered?.map((el) => {
          console.log(el.user);

          return (
            <WithdrawalsToDoCard
              username={el.user.username}
              tgId={el.user.id}
              rewards={el.withdrawals}
              walletAddress={el.user.tonAddress}
              totalAmount={el.total}
              type={type}
            />
          );
        })}
      </div>
    </div>
  );
};
