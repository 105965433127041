/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { PageLayout } from '@app/components';
import { EditQuestForm } from '@app/components';
import { Loader } from '@app/ui-kit';
import { QuestFormValues, QuestRaw, UpdateQuestParams } from '@app/types';
import { mapQuest } from '@app/services/QuestService/mappers';
import {
  notifySuccess,
  notifyError,
  notifyInfo,
} from '@ui-kit/ToastNotifications';

import { questService } from '@app/services';

import s from './EditQuestPage.module.scss';

export const EditQuestPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [quests, setQuests] = useState<QuestRaw[]>([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await questService.fetchQuests();
      setQuests(questService.quests);
    };

    if (questService.quests.length > 0) {
      setQuests(questService.quests);
    } else {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedQuest = useMemo(
    () => quests.find((q) => q.id === id),
    [quests, id],
  );

  const updateQuestHandler = (): SubmitHandler<QuestFormValues> => {
    return async (data: any) => {
      try {
        setShowLoader(true);
        console.log('onSubmit: ', data);
        notifyInfo('Update quest, please wait...');

        const newData = mapQuest(data);

        console.log('Form data:', newData);
        await questService.updateQuest(newData as UpdateQuestParams);
        notifySuccess('Update quest successful!');
        setShowLoader(false);
        await questService.fetchQuests();
        setQuests(questService.quests);
      } catch (error) {
        notifyError('Update quest failed. Please try again.');
        console.error('Error submitting form:', error);
        setShowLoader(false);
      } finally {
        setTimeout(() => {
          navigate('/');
        }, 2500);
      }
    };
  };

  return (
    <PageLayout
      className={s.root}
      classNameHeader={s.header}
      ContainerClassName={s.container}
      onClose={() => navigate(-1)}
      renderHeader={() => <></>}
    >
      {selectedQuest ? (
        <EditQuestForm
          className={s.questForm}
          quest={selectedQuest}
          onSubmit={updateQuestHandler()}
        />
      ) : (
        <Loader show={true} />
      )}

      <Loader show={showLoader} />
    </PageLayout>
  );
};
