/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checker,
  CheckerType,
  CreateQuestParams,
  League,
  QuestCategory,
  QuestFormTask,
  QuestFormValues,
  UpdateQuestParams,
  QuestRaw,
  QuestType,
} from '@app/types';

export const mapChecker = (data: QuestFormValues | QuestFormTask): Checker => {
  console.log('mapChecker: ', data.checker);

  switch (data.checker) {
    case CheckerType.TELEGRAM:
      return {
        type: data.checker,
        telegramChannelId: data.telegramChannelId ?? '',
      };
    case CheckerType.BALANCE:
      return { type: data.checker, balance: data.balance ?? 0 };
    case CheckerType.LEAGUE:
      return { type: data.checker, league: data.league as League };
    case CheckerType.DEFAULT:
    case CheckerType.WALLET_ADDRESS:
    case CheckerType.EMAIL_SUBSCRIPTION:
    case CheckerType.SQUAD:
    case CheckerType.INTIVE_FRIEND:
      return { type: data.checker };
    default:
      return { type: CheckerType.DEFAULT };
  }
};

const mapQuestCategory = (category: string): QuestCategory => {
  switch (category) {
    case QuestCategory.SlapQuests:
      return QuestCategory.SlapQuests;
    case QuestCategory.Social:
      return QuestCategory.Social;
    case QuestCategory.Investor:
      return QuestCategory.Investor;
    case QuestCategory.Partners:
      return QuestCategory.Partners;
    default:
      throw new Error(`Invalid category: ${category}`);
  }
};

export const mapQuest = (
  data: QuestFormValues,
): CreateQuestParams | UpdateQuestParams => {
  return {
    ...data,
    category: mapQuestCategory(data.category),
    dependsOn: data.dependsOn?.split(',').filter((item: string) => !!item),
    type: data.type,
    rewardAmount: parseInt(data.rewardAmount.toString()),
    maxCompleteTimes: !data.maxCompleteTimes
      ? 0
      : parseInt(data.maxCompleteTimes.toString()),
    regions: data.regions?.split(',').filter((item: string) => !!item),
    excludedRegions: data.excludedRegions
      ?.split(',')
      .filter((item: string) => !!item),
    whiteListedUsers: data.whiteListedUsers
      ?.split(',')
      .filter((item: string) => !!item)
      .map((item: string) => parseInt(item)),

    checker: data.checker ? mapChecker(data) : undefined,
    tasks: data.tasks.map((task: any, index) => ({
      ...task,
      id: index,
      checker: mapChecker(task),
    })),
  };
};
