/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';

import { PageLayout } from '@app/components';
import { CreateQuestForm } from '@app/components';
import { FormRef } from '@app/components/CreateQuestForm';
import { Loader } from '@app/ui-kit';
import { CreateQuestParams } from '@app/types';
import { mapQuest } from '@app/services/QuestService/mappers';
import { draftService, questService } from '@app/services';
import { generateQuestId } from './utils';
import {
  notifySuccess,
  notifyError,
  notifyInfo,
} from '@ui-kit/ToastNotifications';

import s from './CreateQuestPage.module.scss';

export const CreateQuestPage: React.FC = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormRef>(null);
  const { draftId } = useParams();

  const [showLoader, setShowLoader] = useState(false);

  const draft = draftId ? draftService.getDraftById(draftId) : undefined;

  const createQuestHandler = (): SubmitHandler<CreateQuestParams> => {
    return async (data: any) => {
      try {
        setShowLoader(true);
        notifyInfo('Create quest, please wait...');

        const formData = {
          ...data,
          _id: generateQuestId(data.title),
        };

        console.log('onSubmit: ', formData);

        const newData = mapQuest(formData);

        await questService.createQuest(newData as CreateQuestParams);
        notifySuccess('Quest creation successful!');
        setShowLoader(false);

        await questService.fetchQuests();
      } catch (error) {
        notifyError('Quest creation failed. Please try again.');
        console.error('Error submitting form:', error);
        setShowLoader(false);
      }
    };
  };

  useEffect(() => {
    const formMethods = formRef.current;

    const saveStateToCache = () => {
      let formData = formMethods?.getValues();

      console.log(formData);

      if (!formData) {
        return;
      }

      if (formData.title) {
        formData = {
          ...formData,
          _id: generateQuestId(formData.title),
        };
      }

      const isFormFilled = Object.values(formData).some((value) => {
        if (Array.isArray(value)) {
          return value.length !== 0;
        }

        return !!value;
      });

      if (isFormFilled) {
        draftService.createOrUpdateDraft(formData);
      }
    };

    window.addEventListener('beforeunload', saveStateToCache);

    return () => {
      window.removeEventListener('beforeunload', saveStateToCache);
      saveStateToCache();
    };
  }, [formRef]);

  return (
    <PageLayout
      className={s.root}
      classNameHeader={s.header}
      ContainerClassName={s.container}
      renderHeader={() => <></>}
      onClose={() => navigate(-1)}
    >
      <CreateQuestForm
        ref={formRef}
        className={s.createQuestForm}
        defaultValues={draft}
        onSubmit={createQuestHandler()}
      />
      <Loader show={showLoader} />
    </PageLayout>
  );
};
