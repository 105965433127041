import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { ReactComponent as CloseIcon } from '@icons/close.svg';

import styles from './SideBarModal.module.scss';

interface SideBarModalProps {
  className?: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export const SideBarModal: React.FC<SideBarModalProps> = ({
  className,
  children,
  open,
  onClose,
}) => {
  const classNames = clsx(styles.root, className, { [styles.open]: open });

  return createPortal(
    <div className={classNames}>
      <div className={styles.cover}></div>
      <div className={styles.content}>
        <button className={styles.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
        {children}
      </div>
    </div>,
    document.body,
  );
};
