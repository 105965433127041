export interface QuestTask {
  id: number;
  title: string;
  description?: string;
  icon?: string;
  url: string;
  checker: Checker;
}

export interface QuestRaw {
  id: string;
  icon: string;
  image?: string;
  category: string;
  type: 'simple' | 'complex';
  title: string;
  subtitle?: string;
  description?: string;
  rewardAmount: number;
  url?: string;
  buttonTitle?: string;
  isLocked?: boolean;
  lockMessage?: string;
  completeTimes?: number;
  maxCompleteTimes?: number;
  regions?: string[];
  excludedRegions?: string[];
  whiteListedUsers?: number[];
  checker?: Checker;
  dependsOn?: number[];
  group?: string;
  tasks?: QuestTask[];
  isArchived?: boolean;
}

export interface QuestFormTask extends Omit<QuestTask, 'checker'> {
  checker: CheckerType;
  balance?: BalanceChecker['balance'];
  telegramChannelId?: TelegramChecker['telegramChannelId'];
  league?: LeagueChecker['league'];
}

export interface QuestFormValues {
  id: string;
  icon: string;
  image?: string;
  category: string;
  type: 'simple' | 'complex';
  title: string;
  subtitle?: string;
  description?: string;
  rewardAmount: string;
  url?: string;
  buttonTitle?: string;
  isLocked?: boolean;
  lockMessage?: string;
  completeTimes?: string;
  maxCompleteTimes?: string;
  regions?: string;
  excludedRegions?: string;
  whiteListedUsers?: string;
  dependsOn?: string;
  group?: string;
  checker?: CheckerType;
  balance?: BalanceChecker['balance'];
  telegramChannelId?: TelegramChecker['telegramChannelId'];
  league?: LeagueChecker['league'];
  tasks: QuestFormTask[];
  isArchived?: boolean;
}

export enum League {
  Common = 'common',
  Rare = 'rare',
  Epic = 'epic',
  Legendary = 'legendary',
  Mythic = 'mythic',
}

export enum QuestCategory {
  SlapQuests = 'Slap quests',
  Social = 'Social',
  Investor = 'Investor',
  Partners = 'Partners',
}

export enum CheckerType {
  DEFAULT = 'default',
  TELEGRAM = 'telegram',
  BALANCE = 'balance',
  WALLET_ADDRESS = 'walletAddress',
  EMAIL_SUBSCRIPTION = 'emailSubscription',
  SQUAD = 'squad',
  INTIVE_FRIEND = 'inviteFriend',
  LEAGUE = 'league',
}

export type QuestType = 'simple' | 'complex';

export interface CreateQuestParams {
  _id: string;
  category: QuestCategory;
  type: QuestType;
  title: string;
  subtitle?: string;
  description?: string;
  icon?: string;
  image?: string;
  url?: string;
  buttonTitle?: string;
  rewardAmount: number;
  maxCompleteTimes?: number;
  group?: string;
  dependsOn?: string[];
  lockMessage?: string;
  regions?: string[];
  excludedRegions?: string[];
  whiteListedUsers?: number[];
  tasks: QuestTask[];
  checker?: Checker;
}

export interface UpdateQuestParams extends Omit<CreateQuestParams, '_id'> {
  id: string;
  isArchived?: boolean;
}

export interface Draft extends CreateQuestParams {
  draftId?: string;
}
export interface DraftRaw extends QuestRaw {
  draftId?: string;
}

export interface TelegramChecker {
  type: CheckerType.TELEGRAM;
  telegramChannelId: string | number;
}

export interface WalletAddressChecker {
  type: CheckerType.WALLET_ADDRESS;
}

export interface EmailSubscribeChecker {
  type: CheckerType.EMAIL_SUBSCRIPTION;
}

export interface BalanceChecker {
  type: CheckerType.BALANCE;
  balance: number;
}

export interface SquadChecker {
  type: CheckerType.SQUAD;
}

export interface InviteFriendChecker {
  type: CheckerType.INTIVE_FRIEND;
}

export interface LeagueChecker {
  type: CheckerType.LEAGUE;
  league: League;
}

export interface DefaultChecker {
  type: CheckerType.DEFAULT;
}

export type Checker =
  | TelegramChecker
  | WalletAddressChecker
  | EmailSubscribeChecker
  | BalanceChecker
  | SquadChecker
  | InviteFriendChecker
  | LeagueChecker
  | DefaultChecker;
