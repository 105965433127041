import {
  CheckerType,
  CreateQuestParams,
  League,
  QuestFormTask,
  QuestFormValues,
  QuestRaw,
  QuestTask,
} from '@app/types';

export const mapQuestCheckerToValue = (
  questOrTask: QuestRaw | CreateQuestParams | QuestTask,
) => {
  switch (questOrTask.checker?.type) {
    case CheckerType.TELEGRAM:
      return {
        telegramChannelId: questOrTask.checker.telegramChannelId,
      };
    case CheckerType.BALANCE:
      return {
        balance: questOrTask.checker.balance,
      };
    case CheckerType.LEAGUE:
      return {
        league: questOrTask.checker.league,
      };
    case CheckerType.DEFAULT:
    case CheckerType.WALLET_ADDRESS:
    case CheckerType.EMAIL_SUBSCRIPTION:
    case CheckerType.SQUAD:
    case CheckerType.INTIVE_FRIEND:
    default:
      return {};
  }
};

export const mapQuestToValues = (quest: QuestRaw): QuestFormValues => {
  const tasks =
    quest.tasks?.map(
      (task): QuestFormTask => ({
        ...task,
        checker: task.checker.type,
        ...mapQuestCheckerToValue(task),
      }),
    ) || [];

  console.log(quest.checker?.type);

  return {
    ...quest,
    id: quest.id,
    category: quest.category,
    regions: quest?.regions?.join(','),
    excludedRegions: quest?.excludedRegions?.join(','),
    whiteListedUsers: quest?.whiteListedUsers?.join(','),
    dependsOn: quest?.dependsOn?.join(','),
    rewardAmount: String(quest?.rewardAmount),
    completeTimes: String(quest?.completeTimes),
    maxCompleteTimes: !quest?.maxCompleteTimes
      ? '0'
      : String(quest?.maxCompleteTimes),
    tasks,
    checker: quest.checker?.type,
    ...mapQuestCheckerToValue(quest),
    isArchived: quest.isArchived,
  };
};
