import './polyfills';

import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import {
  QuestsPage,
  CreateQuestPage,
  EditQuestPage,
  ReferralPage,
} from '@pages';
import { Layout } from '@components';
import { useAuth } from './hooks/useAuth';
import { TournamentPage } from './pages/Tournament';

const App: React.FC = () => {
  const { isLoading } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.Telegram?.WebApp.expand();
    }, 0);
  }, []);

  return (
    <Routes location={location} key={location.pathname}>
      <Route element={<Layout />}>
        <Route path="/" element={<QuestsPage />} />
        <Route path="/create-quest/:draftId?" element={<CreateQuestPage />} />
        <Route path="/edit-quest/:id" element={<EditQuestPage />} />
        <Route path="/tournament" element={<TournamentPage />} />
        <Route path="/referral" element={<ReferralPage />} />
      </Route>
    </Routes>
  );
};

export default App;
