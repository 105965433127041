export enum WithdrawalStatus {
  Pending = 'Pending',
  Queued = 'Queued',
  Processing = 'Processing',
  ProcessingError = 'ProcessingError',
  Completed = 'Completed',
  Rejected = 'Rejected',
}

export enum WithdrawalType {
  TournamentWin = 'TournamentWin',
  Referral = 'Referral',
}

export interface Withdrawal {
  id: string;
  type: WithdrawalType;
  amount: number;
  unlocksAt: string;
  canWithdraw: boolean;
  status: WithdrawalStatus;
  walletAddress?: string;
  txHash?: string;
  createdAt: string;
}

export interface WithdrawalToDo {
  user: {
    id: number;
    username: string;
    tonAddress: string;
    isSuspicious: boolean;
  };
  withdrawals: Withdrawal[];
  total: string;
}

export interface WithdrawalDone {
  id: string;
  userId: number;
  username: string;
  isUserSuspicious: boolean;
  amount: string;
  unlocksAt: string;
  canWithdraw: boolean;
  status: WithdrawalStatus;
  walletAddress: string;
  type: WithdrawalType;
  createdAt: string;
}

export interface WithdrawalsDoneResponse {
  items: WithdrawalDone[];
  limit: number;
  offset: number;
  total: number;
}
