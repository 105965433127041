import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PageLayout, WithdrawalsDone, WithdrawalsToDo } from '@app/components';
import { Loader } from '@app/ui-kit';
import { Tabs } from '@app/ui-kit/Tabs';
import { useNavigate } from 'react-router-dom';
import { useOnCloseApp } from '@app/hooks/useOnCloseApp';
import { withdrawalService } from '@app/services';

import s from './Tournament.module.scss';
import { WithdrawalType } from '@app/types/withdrawal';

enum TournamentTab {
  todo = 'todo',
  done = 'done',
}

export const TournamentPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(TournamentTab.todo);

  const {
    isTournamentWithdrawalsToDoLoading,
    tournamentWithdrawalsTodo,
    tournamentWithdrawalsDone,
    isFetchingMore,
    isTournamentWithdrawalsDoneInitialLoading,
  } = withdrawalService;

  const closeApp = useOnCloseApp(async () => {
    return;
  });

  useEffect(() => {
    withdrawalService.fetchWithdrawalsToDo(WithdrawalType.TournamentWin);
  }, []);

  const handleChangeTab = (tab: TournamentTab) => {
    setCurrentTab(tab);

    const target = document.getElementById('main-layout');

    target?.scrollTo(0, 0);
  };

  return (
    <PageLayout
      className={s.root}
      classNameHeader={s.header}
      ContainerClassName={s.container}
      onClose={closeApp}
      renderHeader={() => (
        <>
          <Tabs
            className={s.tabs}
            variant="secondary"
            tabs={[
              { label: 'Todo', key: TournamentTab.todo },
              { label: 'Done', key: TournamentTab.done },
            ]}
            onTabChange={handleChangeTab}
          />
        </>
      )}
    >
      {isTournamentWithdrawalsToDoLoading ? (
        <Loader show />
      ) : (
        <>
          {currentTab === TournamentTab.todo && (
            <WithdrawalsToDo
              items={tournamentWithdrawalsTodo}
              type={WithdrawalType.TournamentWin}
            />
          )}

          {currentTab === TournamentTab.done && (
            <WithdrawalsDone
              data={tournamentWithdrawalsDone}
              isFetchingMore={isFetchingMore}
              isInitialLoading={isTournamentWithdrawalsDoneInitialLoading}
              type={WithdrawalType.TournamentWin}
            />
          )}
        </>
      )}
    </PageLayout>
  );
});
