import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PageLayout, WithdrawalsDone, WithdrawalsToDo } from '@app/components';
import { Loader } from '@app/ui-kit';
import { Tabs } from '@app/ui-kit/Tabs';
import { useNavigate } from 'react-router-dom';
import { useOnCloseApp } from '@app/hooks/useOnCloseApp';
import { withdrawalService } from '@app/services';

import s from './Referral.module.scss';
import { WithdrawalType } from '@app/types/withdrawal';

enum ReferralTab {
  todo = 'todo',
  done = 'done',
}

export const ReferralPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(ReferralTab.todo);

  const {
    referralsWithdrawalsToDoLoading,
    referralsWithdrawalsTodo,
    referralsWithdrawalsDone,
    referralsWithdrawalsDoneInitialLoading,
    isFetchingMore,
  } = withdrawalService;
  const closeApp = useOnCloseApp(async () => {
    return;
  });

  useEffect(() => {
    withdrawalService.fetchWithdrawalsToDo(WithdrawalType.Referral);
  }, []);

  const handleChangeTab = (tab: ReferralTab) => {
    setCurrentTab(tab);

    const target = document.getElementById('main-layout');

    target?.scrollTo(0, 0);
  };

  return (
    <PageLayout
      className={s.root}
      classNameHeader={s.header}
      ContainerClassName={s.container}
      onClose={closeApp}
      renderHeader={() => (
        <>
          <Tabs
            className={s.tabs}
            variant="secondary"
            tabs={[
              { label: 'Todo', key: ReferralTab.todo },
              { label: 'Done', key: ReferralTab.done },
            ]}
            onTabChange={handleChangeTab}
          />
        </>
      )}
    >
      {referralsWithdrawalsToDoLoading ? (
        <Loader show />
      ) : (
        <>
          <>
            {currentTab === ReferralTab.todo && (
              <WithdrawalsToDo
                items={referralsWithdrawalsTodo}
                type={WithdrawalType.Referral}
              />
            )}

            {currentTab === ReferralTab.done && (
              <WithdrawalsDone
                data={referralsWithdrawalsDone}
                type={WithdrawalType.Referral}
                isInitialLoading={referralsWithdrawalsDoneInitialLoading}
                isFetchingMore={isFetchingMore}
              />
            )}
          </>
        </>
      )}
    </PageLayout>
  );
});
