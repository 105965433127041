import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { WithdrawalDone } from '@app/types/withdrawal';
import { ReactComponent as CopyIcon } from '@icons/copy.svg';
import { Address } from '@ton/core';
import { notifySuccess } from '@app/ui-kit/ToastNotifications';
import { Button } from '@app/ui-kit';
import { withdrawalService } from '@app/services';
import { WithdrawalItem } from '../WithdrawalItem';

import s from './WithdrawalDoneCard.module.scss';

interface WithdrawalDoneCardProps extends WithdrawalDone {
  className?: string;
}

export const WithdrawalDoneCard: React.FC<WithdrawalDoneCardProps> = observer(
  ({
    username,
    id,
    userId,
    walletAddress,
    amount,
    createdAt,
    isUserSuspicious,
    type,
    className,
  }) => {
    const { isActionLoading } = withdrawalService;

    const address = walletAddress
      ? Address.parse(walletAddress).toString({
          bounceable: false,
        })
      : '----';

    const copy = (data: string | number) => {
      window.navigator.clipboard.writeText(data.toString());

      notifySuccess(`Copied: ${data}`);
    };

    const markToDo = () => {
      withdrawalService.markToDo(id, type);
    };

    const handleMarkCheater = () => {
      withdrawalService.markWinnerAsCheater(userId, !isUserSuspicious, type);
    };

    return (
      <div className={cn(s.root, className)}>
        <div className={s.row}>
          <span className={s.username}>@{username}</span>
          <button
            className={cn(s.btnCopy, s.tgId)}
            onClick={() => copy(userId)}
          >
            {userId} <CopyIcon className={s.iconCopy} />
          </button>
        </div>
        <WithdrawalItem
          className={s.reward}
          amount={+amount}
          type={type}
          createdAt={createdAt}
        />
        <button
          className={cn(s.btnCopy, s.address)}
          onClick={() => copy(address)}
        >
          <span>{address}</span>
          <CopyIcon className={s.iconCopy} />
        </button>
        <div className={cn(s.row, s.actions)}>
          <Button
            variant="tertiary"
            size="fluid"
            onClick={markToDo}
            disabled={isActionLoading}
            className={cn(s.actionButton, s.done)}
          >
            Mark ToDo
          </Button>
          <Button
            variant="tertiary"
            size="fluid"
            onClick={handleMarkCheater}
            disabled={isActionLoading}
            className={cn(s.actionButton, s.cheater)}
          >
            {isUserSuspicious ? ' Cancel Cheater' : 'Mark Cheater'}
          </Button>
        </div>
      </div>
    );
  },
);
