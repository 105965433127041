import { action, makeAutoObservable, runInAction } from 'mobx';
import {
  WithdrawalsDoneResponse,
  WithdrawalStatus,
  WithdrawalToDo,
  WithdrawalType,
} from '@app/types/withdrawal';
import { apiService } from '..';

export class WithdrawalService {
  tournamentWithdrawalsTodo: WithdrawalToDo[] | null = null;
  tournamentWithdrawalsDone: WithdrawalsDoneResponse | null = null;
  isTournamentWithdrawalsToDoLoading = true;
  isTournamentWithdrawalsDoneInitialLoading = true;

  referralsWithdrawalsTodo: WithdrawalToDo[] | null = null;
  referralsWithdrawalsDone: WithdrawalsDoneResponse | null = null;
  referralsWithdrawalsToDoLoading = true;
  referralsWithdrawalsDoneInitialLoading = true;

  isFetchingMore = false;
  isActionLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async fetchWithdrawalsToDo(type: WithdrawalType) {
    if (type === WithdrawalType.Referral) {
      this.referralsWithdrawalsToDoLoading = true;
    } else {
      this.isTournamentWithdrawalsToDoLoading = true;
    }

    const res = await apiService.get<WithdrawalToDo[]>(
      '/versus/withdrawal/by-user',
      { type },
    );

    if (type === WithdrawalType.Referral) {
      this.referralsWithdrawalsTodo = res;

      this.referralsWithdrawalsToDoLoading = false;
    } else {
      this.tournamentWithdrawalsTodo = res;
      this.isTournamentWithdrawalsToDoLoading = false;
    }
  }

  @action
  async fetchWithdrawalsDone(type: WithdrawalType, { limit = 15, offset = 0 }) {
    this.isFetchingMore = true;

    try {
      const response = await apiService.get<WithdrawalsDoneResponse>(
        '/versus/withdrawal/all',
        {
          type,
          limit,
          offset,
          sort: 'desc',
          status: [WithdrawalStatus.Completed],
        },
      );

      if (type === WithdrawalType.Referral) {
        if (this.referralsWithdrawalsDone) {
          this.referralsWithdrawalsDone.items = [
            ...response.items,
            ...this.referralsWithdrawalsDone.items,
          ];
          this.referralsWithdrawalsDone.total = response.total;
        } else {
          this.referralsWithdrawalsDone = response;
        }
      }

      if (type === WithdrawalType.TournamentWin) {
        if (this.tournamentWithdrawalsDone) {
          this.tournamentWithdrawalsDone.items = [
            ...response.items,
            ...this.tournamentWithdrawalsDone.items,
          ];
          this.tournamentWithdrawalsDone.total = response.total;
        } else {
          this.tournamentWithdrawalsDone = response;
        }
      }
    } catch (error) {
      console.error(error);
    }

    this.isFetchingMore = false;

    if (type === WithdrawalType.Referral) {
      this.referralsWithdrawalsDoneInitialLoading = false;
    }

    if (type === WithdrawalType.TournamentWin) {
      this.isTournamentWithdrawalsDoneInitialLoading = false;
    }
  }

  @action
  async markDone(withdrawalIds: string[], type: WithdrawalType) {
    this.isActionLoading = true;

    try {
      await apiService.patch(`/versus/withdrawal`, {
        withdrawalIds,
        status: WithdrawalStatus.Completed,
        type,
      });

      await this.resetAndRefetch(type);
    } catch (error) {
      console.error('Failed to mark winner for withdrawal:', error);
    }

    this.isActionLoading = false;
  }

  @action
  async markToDo(winId: string, type: WithdrawalType) {
    this.isActionLoading = true;

    try {
      await apiService.patch(`/versus/withdrawal/${winId}`, {
        status: WithdrawalStatus.Pending,
      });

      await this.resetAndRefetch(type);
    } catch (error) {
      console.error('Failed to mark winner for withdrawal:', error);
    }

    this.isActionLoading = false;
  }

  @action
  async markWinnerAsCheater(
    tgId: number,
    isCheater: boolean,
    type: WithdrawalType,
  ) {
    this.isActionLoading = true;

    try {
      await apiService.patch(`/user/${tgId}`, {
        isSuspicious: isCheater,
      });

      await this.resetAndRefetch(type);
    } catch (error) {
      console.error('Failed to mark winner as cheater:', error);
    }

    this.isActionLoading = false;
  }

  @action
  async resetAndRefetch(type: WithdrawalType) {
    if (type === WithdrawalType.Referral) {
      this.referralsWithdrawalsDoneInitialLoading = true;
      this.referralsWithdrawalsToDoLoading = true;
      this.referralsWithdrawalsDone = null;
      this.referralsWithdrawalsTodo = null;
    }

    if (type === WithdrawalType.TournamentWin) {
      this.isTournamentWithdrawalsDoneInitialLoading = true;
      this.isTournamentWithdrawalsToDoLoading = true;
      this.tournamentWithdrawalsDone = null;
      this.tournamentWithdrawalsTodo = null;
    }

    await this.fetchWithdrawalsToDo(type);
    await this.fetchWithdrawalsDone(type, { limit: 15, offset: 0 });
  }
}
