type AppEnv = 'local' | 'development' | 'production';

export class ConfigService {
  public readonly apiTimeout: number = 30000;
  public readonly apiUrl: string =
    (process.env.REACT_APP_API_URL as string) ||
    'https://3pjxosan1h.execute-api.eu-central-1.amazonaws.com';
  public readonly isDev: boolean = JSON.parse(
    process.env.REACT_APP_IS_DEV || 'true',
  );
  public readonly accessToken: string = process.env
    .REACT_APP_ACCESS_TOKEN as string;
  public readonly initData: string = process.env.REACT_APP_INIT_DATA as string;
}
