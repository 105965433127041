import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import {
  WithdrawalStatus,
  Withdrawal,
  WithdrawalType,
} from '@app/types/withdrawal';
import { ReactComponent as CopyIcon } from '@icons/copy.svg';
import { Address, fromNano } from '@ton/core';
import { notifySuccess } from '@app/ui-kit/ToastNotifications';
import { Button } from '@app/ui-kit';
import { withdrawalService } from '@app/services';
import { WithdrawalItem } from '../WithdrawalItem';

import s from './WithdrawalsToDoCard.module.scss';

interface WithdrawalsToDoCardProps {
  username: string;
  tgId: number;
  walletAddress: string;
  totalAmount: string;
  rewards: Withdrawal[];
  type: WithdrawalType;
  className?: string;
}

export const WithdrawalsToDoCard: React.FC<WithdrawalsToDoCardProps> = observer(
  ({
    username,
    tgId,
    walletAddress,
    rewards,
    totalAmount,
    type,
    className,
  }) => {
    const { isActionLoading } = withdrawalService;

    const address = walletAddress
      ? Address.parse(walletAddress).toString({
          bounceable: false,
        })
      : '----';

    const copy = (data: string | number) => {
      window.navigator.clipboard.writeText(data.toString());

      notifySuccess(`Copied: ${data}`);
    };

    const markDone = () => {
      const ids = rewards.map((el) => el.id);

      withdrawalService.markDone(ids, type);
    };
    const markCheater = () => {
      withdrawalService.markWinnerAsCheater(tgId, true, type);
    };

    const filteredRewards = rewards?.filter(
      (el) => el.status !== WithdrawalStatus.Completed,
    );

    return (
      <div className={cn(s.root, className)}>
        <div className={s.row}>
          <span className={s.username}>@{username}</span>
          <button className={cn(s.btnCopy, s.tgId)} onClick={() => copy(tgId)}>
            {tgId} <CopyIcon className={s.iconCopy} />
          </button>
        </div>
        <div className={s.rewardsList}>
          {filteredRewards?.map((reward) => (
            <WithdrawalItem {...reward} key={reward.createdAt} />
          ))}
        </div>
        <button
          className={cn(s.btnCopy, s.address)}
          onClick={() => copy(address)}
        >
          <span>{address}</span>
          <CopyIcon className={s.iconCopy} />
        </button>
        <div className={cn(s.row, s.total)}>
          <span>Total:</span>
          <span>{fromNano(totalAmount)} TON</span>
        </div>
        <div className={cn(s.row, s.actions)}>
          <Button
            variant="tertiary"
            size="fluid"
            onClick={markDone}
            disabled={isActionLoading}
            className={cn(s.actionButton, s.done)}
          >
            Mark Done
          </Button>
          <Button
            variant="tertiary"
            size="fluid"
            onClick={markCheater}
            disabled={isActionLoading}
            className={cn(s.actionButton, s.cheater)}
          >
            Mark Cheater
          </Button>
        </div>
      </div>
    );
  },
);
