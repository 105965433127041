import { CheckerType, League } from '@app/types';

export const optionsCategory = [
  { value: '', label: 'Select category' },
  { value: 'Slap quests', label: 'Slap quests' },
  { value: 'Social', label: 'Social' },
  { value: 'Investor', label: 'Investor' },
  { value: 'Partners', label: 'Partners' },
];
export const optionsType = [
  { value: 'simple', label: 'Simple' },
  { value: 'complex', label: 'Complex' },
];

export const optionsCheckerType = [
  { value: '', label: 'Select checker type' },
  { value: CheckerType.DEFAULT, label: 'DEFAULT' },
  { value: CheckerType.TELEGRAM, label: 'TELEGRAM' },
  { value: CheckerType.BALANCE, label: 'BALANCE' },
  { value: CheckerType.WALLET_ADDRESS, label: 'WALLET_ADDRESS' },
  { value: CheckerType.EMAIL_SUBSCRIPTION, label: 'EMAIL_SUBSCRIPTION' },
  { value: CheckerType.SQUAD, label: 'SQUAD' },
  { value: CheckerType.INTIVE_FRIEND, label: 'INTIVE_FRIEND' },
  { value: CheckerType.LEAGUE, label: 'LEAGUE' },
];

export const optionsLeague = [
  { value: '', label: 'Select league' },
  { value: League.Rare, label: 'Rare' },
  { value: League.Common, label: 'Common' },
  { value: League.Epic, label: 'Epic' },
  { value: League.Legendary, label: 'Legendary' },
  { value: League.Mythic, label: 'Mythic' },
];
