import { ApiService } from '../ApiService';
import { CacheService } from '../CacheService';
import type {
  CreateQuestParams,
  QuestRaw,
  UpdateQuestParams,
} from '@app/types';

export class QuestService {
  public quests: QuestRaw[] = [];

  public isQuestsLoading = false;

  public isCheckCompletionLoading = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly cacheService: CacheService,
  ) {}

  public async fetchQuests(): Promise<void> {
    this.isQuestsLoading = true;

    this.quests = await this.apiService.get<QuestRaw[]>('/admin/quest/all');

    this.isQuestsLoading = false;
  }

  public async createQuest(params: CreateQuestParams) {
    return this.apiService.post('/admin/quest', params);
  }

  public async updateQuest(params: UpdateQuestParams) {
    return this.apiService.put('/admin/quest', params);
  }
}
