import React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import styles from './ToggleCheckbox.module.scss'; // Import the SCSS module
import clsx from 'clsx';

interface ToggleCheckboxProps {
  label: string;
  name: string;
  required?: boolean;
  className?: string;
  warningMessage?: string;
}

export const ToggleCheckbox: React.FC<ToggleCheckboxProps> = ({
  label,
  className,
  name,
  required = false,
  warningMessage,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const currentValue = watch(name);

  const handleChange = () => {
    if (
      window.confirm(
        warningMessage ||
          'Are you sure you want to add the quest to the archive?',
      )
    ) {
      setValue(name, !currentValue);
    }
  };

  return (
    <div className={clsx(styles.root, className)}>
      <span className={styles.label}>{label}</span>
      <input
        className={`${styles.tgl} ${styles.tglIos}`}
        id={`toggle-${name}`}
        type="checkbox"
        checked={currentValue}
        required={required}
        {...register(name, {
          onChange: (e) => {
            handleChange();
          },
        })}
      />
      <label className={styles.tglBtn} htmlFor={`toggle-${name}`}></label>

      {errors[name] && <span>This field is required</span>}
    </div>
  );
};
