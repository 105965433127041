import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

type ButtonSize = 'small' | 'large' | 'fluid';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: ButtonSize;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  variant = 'primary',
  size = 'small',
  leftIcon,
  rightIcon,
  ...restProps
}) => {
  const content = (
    <>
      {leftIcon && React.cloneElement(leftIcon, { className: styles.iconLeft })}
      {rightIcon &&
        React.cloneElement(rightIcon, { className: styles.iconRight })}

      <span className={styles.content}>{children}</span>
    </>
  );

  const classNames = clsx(
    styles.root,
    styles[variant],
    styles[size],
    className,
  );

  return (
    <button
      className={clsx(classNames, {
        [styles.disabled]: restProps.disabled,
      })}
      {...restProps}
    >
      {content}
    </button>
  );
};
