/* eslint-disable jsx-a11y/alt-text */
import clsx from 'clsx';

import { QuestItem } from '../QuestItem';

import styles from './DraftItem.module.scss';

interface DraftItemProps {
  level?: number;
  className?: string;
  icon?: string;
  title?: string;
  desc?: string;
  type?: string;
  scoreAmount?: number | string;
  isLocked?: boolean;
  lockedMessage?: string;
  isCompleted?: boolean;
  completeTimes?: number;
  maxCompleteTimes?: number;
  itemQuantity?: number;
  itemCompleted?: number;
  onClose?: () => void;
  onClick?: () => void;
}

export const DraftItem: React.FC<DraftItemProps> = ({
  className,
  icon,
  title,
  desc,
  lockedMessage,
  scoreAmount,
  completeTimes,
  maxCompleteTimes,
  onClose,
  onClick,
}) => (
  <div className={clsx(styles.root, className)}>
    <QuestItem
      title={title || 'Title must be here'}
      desc={desc}
      scoreAmount={scoreAmount}
      icon={icon || ''}
      lockedMessage={lockedMessage}
      completeTimes={completeTimes}
      maxCompleteTimes={maxCompleteTimes}
      onClick={onClick}
    />
    <button className={styles.closeBtn} onClick={onClose}>
      <span>Delete draft</span>
    </button>
  </div>
);
