import { forwardRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from '@app/ui-kit';
import { FormInput } from '../FormInput';
import { CheckerSelect } from '../CheckerSelect';

import { ReactComponent as CloseIcon } from '@icons/close.svg';

import s from './TaskForm.module.scss';

const TaskForm = forwardRef<HTMLDivElement>((_, ref) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });

  return (
    <div className={s.root}>
      {fields.map((item, index) => (
        <div className={s.container} key={item.id}>
          <label className={s.title}>
            <span>Task</span>
            <span>{index + 1}</span>
          </label>

          <div className={s.wrap}>
            <button
              className={s.closeBtn}
              type="button"
              onClick={() => remove(index)}
            >
              <CloseIcon />
            </button>
            <FormInput
              className={s.input}
              inputColor="brown"
              label="Title"
              name={`tasks[${index}].title`}
              required
            />
            <FormInput
              className={s.input}
              inputColor="brown"
              label="Description"
              name={`tasks[${index}].description`}
            />
            <FormInput
              className={s.input}
              inputColor="brown"
              label="Url"
              name={`tasks[${index}].url`}
              required
            />
            <CheckerSelect
              selectColor="brown"
              className={s.input}
              baseName={`tasks[${index}]`}
              label="Checker"
              required
            />
          </div>
        </div>
      ))}
      <Button
        type="button"
        onClick={() =>
          append({ title: '', description: '', url: '', telegramChannelId: '' })
        }
      >
        Add Task
      </Button>
    </div>
  );
});

export default TaskForm;
