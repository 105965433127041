import React from 'react';
import clsx from 'clsx';

import styles from './PageLayout.module.scss';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../BackButton';
import { useOnCloseApp } from '@app/hooks/useOnCloseApp';

interface PageLayoutProps {
  className?: string;
  classNameHeader?: string;
  ContainerClassName?: string;
  children: React.ReactNode;
  renderHeader: () => React.ReactNode;
  onClose?: () => void;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  className,
  classNameHeader,
  children,
  ContainerClassName,
  renderHeader,
  onClose,
}) => {
  return (
    <div className={clsx(styles.root, className)}>
      <BackButton onClick={onClose} />
      <header className={clsx(styles.header, classNameHeader)}>
        {renderHeader()}
      </header>
      <div
        className={clsx(styles.main, ContainerClassName)}
        id="main-scrollable"
      >
        {children}
      </div>
    </div>
  );
};
