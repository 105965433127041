/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FormEventHandler,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import clsx from 'clsx';

import { FormInput, FormSelect, TaskForm, FormImgInput } from '@components';
import { Button } from '@app/ui-kit';
import { CreateQuestParams } from '@app/types';
import { optionsCategory, optionsType } from '@app/constants/selectOptions';
import { CheckerSelect } from '../CheckerSelect';
import { parseQuestData } from './utils';

import s from './CreateQuestForm.module.scss';

interface CreateQuestFormProps {
  className?: string;
  defaultValues?: Partial<CreateQuestParams>;
  onSubmit: SubmitHandler<CreateQuestParams>;
}

export type FormRef = UseFormReturn<CreateQuestParams, any, undefined>;

export const CreateQuestForm = forwardRef<FormRef, CreateQuestFormProps>(
  ({ className, defaultValues, onSubmit }, ref) => {
    const formMethods = useForm<CreateQuestParams>({
      defaultValues,
    });

    const { formState, handleSubmit, reset, watch, setValue } = formMethods;
    const type = watch('type');

    useImperativeHandle(ref, () => formMethods);

    const _onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
      await handleSubmit(onSubmit)(e);

      if (formState.isValid) {
        reset();
      }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const text = e.clipboardData.getData('text/plain');
      const parsedQuest = parseQuestData(text);

      if (!parsedQuest) {
        return;
      }

      e.preventDefault();

      for (const key in parsedQuest) {
        if (parsedQuest[key as keyof typeof parsedQuest]) {
          setValue(
            key as keyof CreateQuestParams,
            parsedQuest[key as keyof typeof parsedQuest],
          );
        }
      }
    };

    return (
      <div className={clsx(s.root, className)}>
        <h2 className={s.title}>Create Quest</h2>
        <FormProvider {...formMethods}>
          <form className={s.form} onSubmit={_onSubmit}>
            <fieldset className={s.fieldset}>
              <legend className={s.fieldsetTitle}>General</legend>
              <FormInput
                className={s.input}
                label="Title"
                name="title"
                onPaste={handlePaste}
                required
              />
              <FormInput className={s.input} label="Subtitle" name="subtitle" />
              <FormInput
                className={s.input}
                label="Description"
                name="description"
              />
              <FormSelect
                className={s.input}
                label="Category"
                name="category"
                required
                options={optionsCategory}
              />
              <FormInput
                className={s.input}
                label="Reward amount"
                type="number"
                name="rewardAmount"
                required
              />

              <FormImgInput
                className={s.input}
                label="Icon"
                name="icon"
                type="url"
                required
              />
              <FormImgInput
                className={s.input}
                label="Image"
                name="image"
                type="url"
              />
            </fieldset>
            <fieldset className={s.fieldset}>
              <legend className={s.fieldsetTitle}>Restrictions</legend>
              <FormInput
                className={s.input}
                label="Regions"
                name="regions"
                placeholder="Enter language codes: en, uk, de"
                hint={
                  <>
                    Supported language codes:{' '}
                    <a href="https://en.wikipedia.org/wiki/IETF_language_tag">
                      IETF language tag
                    </a>
                  </>
                }
              />
              <FormInput
                className={s.input}
                label="Excluded regions"
                name="excludedRegions"
                placeholder="Enter language codes: en, uk, de"
                hint={
                  <>
                    Supported language codes:{' '}
                    <a href="https://en.wikipedia.org/wiki/IETF_language_tag">
                      IETF language tag
                    </a>
                  </>
                }
              />
              <FormInput
                className={s.input}
                label="White listed users"
                name="whiteListedUsers"
                placeholder="Enter telegram IDs"
                hint="Enter one or more telegram IDs separated by commas: 6569791522, 6569791523"
              />
              <FormInput
                className={s.input}
                label="Max complete times"
                type="number"
                name="maxCompleteTimes"
              />
              <FormInput
                className={s.input}
                label="Lock message"
                name="lockMessage"
                hint="Message that will be shown to users when the quest is locked by other depending quests"
              />
            </fieldset>
            <fieldset className={s.fieldset}>
              <legend className={s.fieldsetTitle}>Quest type</legend>
              <FormSelect
                className={s.input}
                label="Type"
                name="type"
                required
                options={optionsType}
              />
            </fieldset>
            {type === 'simple' && (
              <fieldset className={s.fieldset}>
                <legend className={s.fieldsetTitle}>
                  Only for simple quest without task!
                </legend>
                <CheckerSelect className={s.input} label="Checker" required />
                <FormInput
                  className={s.input}
                  label="Url"
                  name="url"
                  required
                />
                <FormInput
                  className={s.input}
                  label="Button title"
                  name="buttonTitle"
                  required
                />
              </fieldset>
            )}

            {type === 'complex' && <TaskForm />}

            <Button
              className={s.submit}
              type="submit"
              variant="secondary"
              size="large"
            >
              Create Quest
            </Button>
          </form>
        </FormProvider>
      </div>
    );
  },
);
