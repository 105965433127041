export interface NavLinkItem {
  label: string;
  href: string;
}

export const NAV_LINKS: NavLinkItem[] = [
  {
    label: 'Quests',
    href: '/',
  },
  {
    label: 'Tournament',
    href: '/tournament',
  },
  {
    label: 'Referral',
    href: '/referral',
  },
];
