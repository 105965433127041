import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../Sidebar';
import { Button, SideBarModal } from '@ui-kit';
import styles from './Layout.module.scss';

export const Layout: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenPoup = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <h1 className={styles.heading}>Slap Admin panel</h1>
        <Button variant="secondary" size="small" onClick={handleOpenPoup}>
          Menu
        </Button>
      </header>
      <Sidebar className={styles.sidebar} />
      <div className={styles.main}>
        <Outlet />
      </div>
      <SideBarModal
        className={styles.sideBarModal}
        open={isOpen}
        onClose={handleClose}
      >
        <Sidebar className={styles.sidebarMobile} />
      </SideBarModal>
    </div>
  );
};
