/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEventHandler, forwardRef, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import clsx from 'clsx';

import {
  FormInput,
  FormSelect,
  TaskForm,
  FormImgInput,
  ToggleCheckbox,
} from '@components';
import { QuestFormValues, QuestRaw } from '@app/types';
import { Button } from '@app/ui-kit';

import { optionsCategory, optionsType } from '@app/constants/selectOptions';

import s from './EditQuestForm.module.scss';
import { CheckerSelect } from '../CheckerSelect';
import { mapQuestToValues } from './mapper';

interface EditQuestFormProps {
  className?: string;
  quest: QuestRaw;
  onSubmit: SubmitHandler<QuestFormValues>;
}

const EditQuestForm: React.ForwardRefRenderFunction<any, EditQuestFormProps> = (
  { quest, className, onSubmit },
  ref,
) => {
  console.log('defaultValues: ', mapQuestToValues(quest));

  const formMethods = useForm<QuestFormValues>({
    defaultValues: mapQuestToValues(quest),
  });

  const { handleSubmit, reset, watch } = formMethods;

  const type = watch('type');

  useEffect(() => {
    reset(mapQuestToValues(quest));
  }, [quest, reset]);

  const _onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (type === 'simple') {
      reset((formValues) => ({ ...formValues, tasks: [] }));
    } else if (type === 'complex') {
      reset((formValues) => ({ ...formValues, checker: undefined }));
    }
  }, [type, reset]);

  return (
    <div className={clsx(s.root, className)}>
      <h2 className={s.title}>Edit Quest</h2>
      <FormProvider {...formMethods}>
        <form className={s.form} onSubmit={_onSubmit}>
          <fieldset className={s.fieldset}>
            <legend className={s.fieldsetTitle}>General</legend>
            <FormInput
              className={s.input}
              label="Title"
              name="title"
              required
            />
            <FormInput className={s.input} label="Subtitle" name="subtitle" />
            <FormInput
              className={s.input}
              label="Description"
              name="description"
            />
            <FormSelect
              className={s.input}
              label="Category"
              name="category"
              options={optionsCategory}
              required
            />
            <FormInput
              className={s.input}
              label="Reward amount"
              type="number"
              name="rewardAmount"
              required
            />
            <FormImgInput
              className={s.input}
              label="Icon"
              name="icon"
              required
            />
            <FormImgInput className={s.input} label="Image" name="image" />
          </fieldset>
          <fieldset className={s.fieldset}>
            <legend className={s.fieldsetTitle}>restrictions</legend>
            <FormInput
              className={s.input}
              label="Regions"
              name="regions"
              placeholder="Enter language codes: en, uk, de"
              hint={
                <>
                  Supported language codes:{' '}
                  <a href="https://en.wikipedia.org/wiki/IETF_language_tag">
                    IETF language tag
                  </a>
                </>
              }
            />
            <FormInput
              className={s.input}
              label="Excluded regions"
              name="excludedRegions"
              placeholder="Enter language codes: en, uk, de"
              hint={
                <>
                  Supported language codes:{' '}
                  <a href="https://en.wikipedia.org/wiki/IETF_language_tag">
                    IETF language tag
                  </a>
                </>
              }
            />
            <FormInput
              className={s.input}
              label="White listed users"
              name="whiteListedUsers"
              placeholder="Enter telegram IDs"
              hint="Enter one or more telegram IDs separated by commas: 6569791522, 6569791523"
            />
            <FormInput
              className={s.input}
              label="Max complete times"
              type="number"
              name="maxCompleteTimes"
            />
            <FormInput
              className={s.input}
              label="Lock message"
              name="lockMessage"
            />
          </fieldset>
          <fieldset className={s.fieldset}>
            <legend className={s.fieldsetTitle}>Quest type</legend>
            <FormSelect
              className={s.input}
              label="Type"
              name="type"
              required
              options={optionsType}
            />
          </fieldset>

          {type === 'simple' ? (
            <fieldset className={s.fieldset}>
              <legend className={s.fieldsetTitle}>
                Only for simple quest without task!
              </legend>
              <CheckerSelect className={s.input} label="Checker" required />
              <FormInput className={s.input} label="Url" name="url" required />
              <FormInput
                className={s.input}
                label="Button title"
                name="buttonTitle"
                required
              />
            </fieldset>
          ) : null}

          {type === 'complex' && <TaskForm />}
          <fieldset className={clsx(s.fieldset, s.archive)}>
            <legend className={s.fieldsetTitle}>Archive</legend>
            <ToggleCheckbox
              className={s.toggle}
              name="isArchived"
              label="Add to archive"
            />
          </fieldset>
          <Button
            className={s.submit}
            type="submit"
            variant="secondary"
            size="large"
          >
            Update Quest
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default forwardRef(EditQuestForm);
