import { parseDate } from '@app/utils/formatDate';
import { fromNano } from '@ton/core';
import { Withdrawal, WithdrawalType } from '@app/types/withdrawal';
import cn from 'classnames';

import s from './WithdrawalItem.module.scss';

export interface WithdrawalItemProps
  extends Pick<Withdrawal, 'createdAt' | 'amount' | 'type'> {
  className?: string;
}

export const WithdrawalItem: React.FC<WithdrawalItemProps> = ({
  createdAt,
  amount,
  type,
  className,
}) => {
  const { date, time } = parseDate(createdAt);

  return (
    <div className={cn(s.reward, className)}>
      <div className={s.dateWrapper}>
        <span className={s.date}>{date}</span>
        <span className={s.time}>{time}</span>
      </div>
      <div className={s.rewardAmount}>
        <span>{fromNano(amount)} TON</span>
      </div>
    </div>
  );
};
