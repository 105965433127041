import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { Alert } from '../Alert';

import styles from './Loader.module.scss';

interface LoaderProps {
  className?: string;
  show: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ className, show }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [show]);

  // if (!isVisible) return null;

  return (
    <div className={clsx(styles.root, className, { [styles.show]: show })}>
      <div className={styles.cover}></div>
      <span className={styles.loader}></span>
    </div>
  );
};
