import React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

import s from './FormInput.module.scss';

type NativeInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'pattern'
>;
interface InputProps extends NativeInputProps {
  label: string;
  name: string;
  required?: boolean;
  pattern?: RegExp;
  className?: string;
  placeholder?: string;
  type?: string;
  inputColor?: 'brown' | 'dark-brown';
  hint?: React.ReactNode;
  mask?: string | RegExp;
}

export const FormInput: React.FC<InputProps> = ({
  label,
  className,
  name,
  required = false,
  pattern,
  placeholder,
  type = 'text',
  inputColor = 'dark-brown',
  hint,
  mask,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = Boolean(errors?.[name]);

  const { onChange, ...registerRest } = register(name, {
    required: required,
    pattern: pattern,
  });

  const inputClassNames = clsx(s.input, s[inputColor], {
    [s.errorInput]: hasError,
  });

  const _input = mask ? (
    <IMaskInput
      className={inputClassNames}
      id={name}
      type={type}
      required={required}
      placeholder={placeholder}
      onAccept={(value: string) => {
        onChange({ target: { name: registerRest.name, value } });
      }}
      mask={mask as string}
      onPaste={rest.onPaste}
      {...registerRest}
    />
  ) : (
    <input
      {...rest}
      id={name}
      type={type}
      className={inputClassNames}
      placeholder={placeholder}
      onChange={onChange}
      {...registerRest}
    />
  );

  return (
    <div className={clsx(s.root, className, s[type])}>
      <label className={s.inputLable} htmlFor={name}>
        {label}
        {!required && <span className={s.optional}>(optional)</span>}
      </label>
      {_input}
      {hint && <span className={s.hint}>{hint}</span>}
      {type === 'checkbox' && <span className={s.checkmark}></span>}
      {hasError && (
        <span className={s.error}>
          {errors[name]?.type === 'required' && 'This field is required'}
          {errors[name]?.type === 'pattern' && 'Invalid format'}
        </span>
      )}
    </div>
  );
};
