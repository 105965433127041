import { useEffect, useState } from 'react';

import { configService, userService } from '@app/services';

export const useAuth = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initGame = async () => {
      await userService.fetchProfile();
      setIsAuth(true);

      setIsLoading(false);
    };

    if (!isAuth) {
      const initData = configService.isDev
        ? configService.initData
        : window.Telegram?.WebApp?.initData;

      userService.login(initData).then(initGame);

      return;
    }

    initGame();
  }, [isAuth]);

  return { isLoading, isAuth };
};
