import { UserProfile } from '@app/types/users';
import { ApiService } from '../ApiService';
import { AuthService } from '../AuthService';

export class UserService {
  public isAuth = false;
  public isProfileLoading = false;
  public userProfile: UserProfile | null = null;
  public isFriendsLoading = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
  ) {
    this.isAuth = this.authService.isAuth();
  }

  public async login(initData: string): Promise<void> {
    const { accessToken } = await this.apiService.post<
      { accessToken: string },
      { initData: string }
    >('/auth/login', {
      initData,
    });

    this.authService.setCredentials(accessToken);
  }

  public async fetchProfile(): Promise<void> {
    this.isProfileLoading = true;
    this.userProfile = await this.apiService.get<UserProfile>(
      '/user/profile',
      {},
    );
    this.isProfileLoading = false;
  }
}
