import React, { useState, ChangeEvent } from 'react';
import cl from 'clsx';

import s from './FormImgInput.module.scss';
import { useFormContext } from 'react-hook-form';

interface FormImgInputProps {
  className?: string;
  label: string;
  name: string;
  required?: boolean;
  value?: string;
  type?: string;
}

export const FormImgInput: React.FC<FormImgInputProps> = ({
  className,
  label,
  name,
  required = false,
  value,
}) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const values = getValues();
  const imageUrl = values[name];
  const hasError = Boolean(errors?.[name]);

  return (
    <div className={cl(s.root, className)}>
      <label className={s.inputLable} htmlFor={'image'}>
        {label}
        {!required && <span className={s.optional}>(optional)</span>}
      </label>

      <input
        type="url"
        className={cl(s.input, { [s.errorInput]: hasError })}
        id={name}
        {...register(name, { required: required })}
      />

      {hasError && (
        <span className={s.error}>
          {errors[name]?.type === 'required' && 'This field is required'}
        </span>
      )}

      <div className={s.container}>
        {imageUrl && imageUrl !== value && (
          <div className={s.imgWrap}>
            <h3 className={s.title}>Selected {label}:</h3>

            <img
              src={imageUrl}
              alt="Selected"
              style={{ maxWidth: '100px', height: 'auto' }}
            />
          </div>
        )}
        {value && (
          <div className={s.imgWrap}>
            <h3 className={s.title}>Current {label}:</h3>

            <img
              src={value}
              alt="Selected"
              style={{ maxWidth: '100px', height: 'auto' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
