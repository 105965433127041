import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect } from 'react';

import { withdrawalService } from '@app/services';
import { WithdrawalDoneCard } from '../WithdrawalDoneCard';
import { Loader } from '@app/ui-kit';
import { WithdrawalsDoneResponse, WithdrawalType } from '@app/types/withdrawal';

import s from './WithdrawalsDone.module.scss';

interface WithdrawalsDoneProps {
  data: WithdrawalsDoneResponse | null;
  type: WithdrawalType;
  isInitialLoading: boolean;
  isFetchingMore: boolean;
  className?: string;
}

export const WithdrawalsDone: React.FC<WithdrawalsDoneProps> = ({
  data,
  type,
  isInitialLoading,
  isFetchingMore,
  className,
}) => {
  const total = data?.total || 0;
  const currentWinnersAmount = data?.items.length || 0;

  useEffect(() => {
    if (!data) {
      withdrawalService.fetchWithdrawalsDone(type, { limit: 14, offset: 0 });
    }
  }, [data, type]);

  return (
    <div className={cn(s.root, className)}>
      {isInitialLoading ? (
        <Loader show />
      ) : (
        <>
          <h3 className={s.total}>
            Total: {total} ({type === WithdrawalType.Referral && 'Referrer'}
            {type === WithdrawalType.TournamentWin && 'Winners'})
          </h3>
          <InfiniteScroll
            scrollableTarget="main-scrollable"
            dataLength={data?.items.length || 0}
            next={() =>
              withdrawalService.fetchWithdrawalsDone(type, {
                limit: 10,
                offset: currentWinnersAmount,
              })
            }
            hasMore={currentWinnersAmount !== total}
            loader={isFetchingMore && <p className={s.loader}>Loading...</p>}
            endMessage={<p className={s.scrollStatus}>No more data</p>}
            className={s.infiniteScroll}
          >
            {data?.items.map((el) => <WithdrawalDoneCard {...el} />)}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};
