/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { EmptyListState, PageLayout } from '@app/components';
import { DraftItem } from '@app/components';
import { Button, Loader } from '@app/ui-kit';
import { ListItems } from '@app/ui-kit/ListItems';
import { QuestItem } from '@app/components/QuestItem';
import { Draft, DraftRaw, QuestRaw } from '@app/types';
import { groupByField } from '@app/utils/groupByField';
import { updateQuestHandler } from '@app/utils/questHandlers';

import { draftService, questService } from '@app/services';

import { Tabs } from '@app/ui-kit/Tabs';

import s from './Quests.module.scss';
import { useNavigate } from 'react-router-dom';
import { useOnCloseApp } from '@app/hooks/useOnCloseApp';

type QuestTab = 'quests' | 'drafts' | 'arhive';

export const QuestsPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<QuestTab>('quests');
  const [quests, setQuests] = useState<QuestRaw[]>([]);
  const [drafts, setDrafts] = useState<DraftRaw[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  console.log(drafts);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      await questService.fetchQuests();
      setQuests(questService.quests);
    } catch (error) {
      console.error('Error fetching quests:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDrafts(draftService.getDraftedQuests());
    fetchData();
  }, []);

  const archiveQuests = quests.filter((q) => {
    const completeTimes = q.completeTimes ?? 0;

    return (
      (q.category === 'Partners' &&
        q.maxCompleteTimes &&
        completeTimes >= q.maxCompleteTimes) ||
      q.isArchived
    );
  });

  const groupedByCategory = useMemo(() => {
    const filteredQuests = quests.filter((q) => {
      const completeTimes = q.completeTimes ?? 0;

      if (
        (q.maxCompleteTimes && completeTimes >= q.maxCompleteTimes) ||
        q.isArchived
      ) {
        return false;
      }

      return q.category;
    });

    return groupByField(filteredQuests, 'category');
  }, [quests]);

  const handleOpen = (quest: QuestRaw) => {
    navigate(`/edit-quest/${quest.id}`);
  };

  const handleOpenDraft = (draft: DraftRaw) => {
    navigate(`/create-quest/${draft.draftId}`);
  };
  const handleDelete = (draft: DraftRaw) => {
    draftService.deleteDraft(draft.draftId as string);
    setDrafts(draftService.getDraftedQuests());
  };

  const navigateToCreationQuest = () => {
    navigate('/create-quest');
  };

  const closeApp = useOnCloseApp(async () => {
    return;
  });

  return (
    <PageLayout
      className={s.root}
      classNameHeader={s.header}
      ContainerClassName={s.container}
      onClose={closeApp}
      renderHeader={() => (
        <>
          <Tabs
            className={s.tabs}
            variant="secondary"
            tabs={[
              { label: 'Quests', key: 'quests' },
              { label: 'Drafts', key: 'drafts' },
              { label: 'Arhive', key: 'arhive' },
            ]}
            onTabChange={setCurrentTab}
          />
        </>
      )}
    >
      {isLoading ? (
        <Loader show />
      ) : (
        <>
          {currentTab === 'quests' && (
            <div className={s.tabContent}>
              <Button onClick={navigateToCreationQuest}>Add Quest</Button>
              {groupedByCategory.map((items, index) => {
                return (
                  <ListItems<QuestRaw>
                    key={index}
                    isLoading={questService.isQuestsLoading}
                    className={s.listWrap}
                    listClassName={s.list}
                    itemClassName={s.listItem}
                    title={items[0].category}
                    items={items}
                    keyExtractor={({ id }) => id}
                    renderItem={(quest) => (
                      <QuestItem
                        type={quest.type}
                        title={quest.title}
                        desc={quest.subtitle}
                        scoreAmount={quest.rewardAmount}
                        icon={quest.icon}
                        isLocked={quest.isLocked}
                        lockedMessage={quest.lockMessage}
                        completeTimes={quest.completeTimes}
                        maxCompleteTimes={quest.maxCompleteTimes}
                        onClick={() => handleOpen(quest)}
                      />
                    )}
                  />
                );
              })}
            </div>
          )}
          {currentTab === 'arhive' && (
            <ListItems<QuestRaw>
              isLoading={questService.isQuestsLoading}
              className={s.listWrap}
              listClassName={s.list}
              itemClassName={s.listItem}
              title={'Archive'}
              items={archiveQuests.length !== 0 ? archiveQuests : []}
              emptyView={
                <EmptyListState variant="nothing-here" borderRadius="rounded" />
              }
              keyExtractor={({ id }) => id}
              renderItem={(quest) => (
                <QuestItem
                  type={quest.type}
                  title={quest.title}
                  desc={quest.subtitle}
                  scoreAmount={quest.rewardAmount}
                  icon={quest.icon}
                  isLocked={quest.isLocked}
                  lockedMessage={quest.lockMessage}
                  completeTimes={quest.completeTimes}
                  maxCompleteTimes={quest.maxCompleteTimes}
                  onClick={() => handleOpen(quest)}
                />
              )}
            />
          )}
          {currentTab === 'drafts' && (
            <ListItems<DraftRaw>
              className={s.listWrap}
              listClassName={s.list}
              itemClassName={s.listItem}
              title={'Drafts'}
              items={drafts.length !== 0 ? drafts : []}
              emptyView={
                <EmptyListState variant="nothing-here" borderRadius="rounded" />
              }
              keyExtractor={({ id }) => id}
              renderItem={(draft) => (
                <DraftItem
                  type={draft.type}
                  title={draft.title}
                  desc={draft.subtitle}
                  scoreAmount={draft.rewardAmount}
                  icon={draft.icon}
                  isLocked={draft.isLocked}
                  lockedMessage={draft.lockMessage}
                  completeTimes={draft.completeTimes}
                  maxCompleteTimes={draft.maxCompleteTimes}
                  onClick={() => handleOpenDraft(draft)}
                  onClose={() => handleDelete(draft)}
                />
              )}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};
